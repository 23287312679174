import React, { useContext, useEffect, useState, useRef } from 'react'
import { Flex, Box } from 'theme-ui'
import { Helmet } from "react-helmet";
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import Tabs from '@solid-ui-components/Educabot/TabsContact/Tabs'
import Modal from '@solid-ui-components/Modal'
import ListItem from '@solid-ui-components/ListItem'
import { TabsContext } from '@solid-ui-components/Tabs'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentMap from '@solid-ui-components/ContentMap'
import { ModalContext } from '@solid-ui-components/Modal'
import { FormContact } from '../../../../solid-ui-components/src/FormContact/FormContact';

const styles = {
  modalContainer: {
    '@media (max-width: 1550px)': {
      maxHeight: '93vh'
    },
    '@media (max-width: 765px)': {
      height: '100%'
    },
  },
  modalContact: {
    display: [`none`, null, `flex`],
    alignItems: 'center',
    flex: 1,
    minWidth: [null, null, null, 500],
    minHeight: '600px',
    position: `relative`,
    overflow: `hidden`,
    bg: '#42B3FF',
  }
}

const ModalBlock01 = ({ content, reverse }) => {
  const { activeModal } = useContext(ModalContext)
  const {
    activeTab: { index = 0 }
  } = useContext(TabsContext)

  const { identifier, text, buttons } = content

  if (activeModal && activeModal !== identifier) return null

  const images = content?.collection[index]?.images || content.images
  const map = content?.collection[index]?.map || content.map
  const hasMedia = images || map

  const webformData = 'https://webforms.pipedrive.com/f/72vZxC3DRRn3pVmSvgsoDpRqP6su8yWfJHSkEZuXiAbD6N1fNXosJpg9slnGWlUMqn'

  return (
    <Modal
        id={identifier}
        contentStyles={{
          maxWidth: hasMedia ? `narrow` : 500,
          p: 0
        }}
      >
      <Box sx={styles.modalContainer}>
        <Flex
          sx={{
            alignItems: `stretch`,
            flexDirection: [
              reverse ? `column-reverse` : `column`,
              reverse ? `row-reverse` : `row`
            ]
          }}
        >
          {hasMedia && (
            <Box sx={styles.modalContact}>
              {images && (
                <ContentImages
                  content={{ images }}
                  imageFit='cover'
                  height='100%'
                  reverse={reverse}
                />
              )}
              {map && (
                <Reveal
                  effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                  style={{ width: `100%`, height: `100%` }}
                >
                  <ContentMap content={map} />
                </Reveal>
              )}
            </Box>
          )}
          {Array.isArray(content.collection) && (
            <Box sx={{ flex: 1, py: [3, 4], px: [3, 5], paddingBottom: '2rem' }}>
              <ContentText content={text} space={3} />
              <Tabs space={3} id={identifier}>
                {content.collection
                  ?.filter(Boolean)
                  ?.map(({ text, collection, form }, index) => (
                    <Reveal
                      key={`item-${index}`}
                      effect='fadeIn'
                      content={{ text }}
                    >
                      {text && (
                        <>
                          <Box sx={{ textAlign: `center`, fontWeight: 600 }}>
                            <ContentText content={text?.slice(1)} />
                          </Box>
                        </>
                      )}
                      {collection?.map((props, index) => (
                        <Box sx={{ padding: '15px 0 0 40px', fontWeight: 600 }}>
                          <ListItem key={`item-${index}`} {...props}compact />
                        </Box>
                      ))}
                      {index === 0 && (
                        <>
                          <Divider space={3} />
                          <FormContact webformData={webformData} />
                        </>
                      )}
                      {/* {form && (
                        <ContentForm
                        form={form}
                        id={`form.${identifier}.${
                          form.multiStep ? 'multi' : index
                        }`}
                        />
                      )} */}
                    </Reveal>
                  ))}
              </Tabs>
            </Box>
          )}
        </Flex>
      </Box>
    </Modal>
  )
}

export default ModalBlock01
