import React, { useContext, useEffect, useState, useRef } from 'react'
import { useInterval } from '../../../solid-ui-blocks/src/utils/hooks'
import Anibot from '../Anibot/Anibot'

export const FormContact = ({ webformData }) => {
    const PIPEDRIVE_WEBFORMS_DIV = 'pipedriveWebForms'
  
    const [formWasSet, setFormWasSet] = useState(false)
    const ref = useRef()
  
    useEffect(() => {
      const script = document.createElement('script')
      script.src = 'https://webforms.pipedrive.com/f/loader'
      script.async = true
      const body = document.querySelector('body')
      if (body) {
        body.appendChild(script)
      }
    }, [])
  
    useInterval(() => {
      if (ref?.current) {
        ref.current.childElementCount > 0 && setFormWasSet(true)
      }
    }, formWasSet ? null : 300)
  
    return (
      <div style={{ position: 'relative' }}>
        <div
          className={PIPEDRIVE_WEBFORMS_DIV}
          style={{
            opacity: formWasSet ? 1 : 0,
            transition: 'opacity 400ms ease 1100ms',
            zIndex: 1,
          }}
          data-pd-webforms={webformData}
          ref={ref}
        />
        <div style={{
          opacity: formWasSet ? 0 : 1,
          height: 320,
          position: 'absolute',
          width: '100%',
          zIndex: 0,
          top: 0,
          left: 0,
          transition: 'opacity 400ms ease 400ms',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
            <Anibot />
        </div>
      </div>
    )
  }