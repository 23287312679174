import React from 'react'

import IconAnibot from '../../../../../site/content/assets/anibot.gif'

const Anibot = () => {
    return (
        <div>
            <img style={{width: '100px', height: '100px'}} src={IconAnibot}/> 
        </div>
    )
}

export default Anibot
